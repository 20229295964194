/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap'); */
body {
background: #ff7000;
color: #ffffff;
font-family: 'Roboto', 'helvetica neue', helvetica, arial, sans-serif;
font-size: 16px;
line-height: 24px;
overflow-x: hidden;
}

@font-face {
  font-family: 'Folio-Bold-Condensed';
  src: url('../fonts/folio_bold_condensed.woff2') format('woff');
  }

h1, h2 {
  text-transform: uppercase;
  font-style: italic;
  font-weight: black;
}

h1 {
  font-size: 68px;
  line-height: 58px;
  letter-spacing: 1px;
}

h2 {
  font-size: 40px;
  line-height: 38px;
  letter-spacing: 1px;
  margin: 0 0 30px;
}

h3 {
  color: #fec524;
  font-family: "Helvetica Neue",Arial,Helvetica,sans-serif;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 1px;
}

a, a:hover {
  color: #ffffff;
}

a:hover {
  text-decoration: underline;
}

p {
  margin-bottom: 30px;
}

.btn {
  background: #fec524;
  border: 0 none;
  font-style: italic;
  text-transform: uppercase;
  color: #ff7000;
}

img {
  width: 100%;
  height: auto;
}

.mt-30 {
  margin-top: 30px;
}

#header {
  height: 48px;
  font-size: 16px;
  padding-left: 16px!important;
  border-bottom: 1px solid rgba(255,255,255,0.25);
  margin-bottom: 60px!important;
}

#header a {
  color: rgb(33, 37, 41);
}

#header img {
  width: 50px;
  height: auto;
}

#header .header-text {
  font-weight: 700;
  text-transform: uppercase;
  color: #fec524;
  font-style: italic;
  font-size: 1.2em;
}

#header .dropdown::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

#menu {
  padding: 1rem!important;
  margin: 0 0 60px;
  display: none;
}

#menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#menu li {
  margin: 0;
  padding: 0 1rem!important;
}

#menu li a {
  padding: 8px 0 8px;
  display: block;
  text-decoration: none;
}

#menu li a.active {
  border-bottom: 3px solid #ffffff;
}

#menu li a:hover {
  text-decoration: none;
}

#btn-play {
  display: none;
}

#week-number {
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 700;
}

#prize-symbol {
  margin-top: 10px;
  text-align: right;
}

#prize-symbol img {
  width: 100px;
  height: auto;
}

.table thead {
  background: #fec524;
  border: 0 none;
}

.table thead tr, .table thead th {
  border: 0 none;
  text-transform: uppercase;
  font-style: italic;
  color: #ff7000;
}



.table tbody tr:first-child td {
  border: 0 none;
}

.tab-content {
  border: 0 none;
}

.card {
  border-radius: 0;
  margin: 0 0 10px;
  border: 0 none;
}

.card-header {
  padding: 15px 10px;
  border: 0 none;
  border-radius: 0 !important;
  background-color: #fec524;
}

.card-header h5 {
  text-transform: none;
  color: #ff7000;
  margin: 0;
}

.tab-content h5 {
  margin: 0;
  text-transform: none;
  color: #ff7000;
}

.tab-content .btn {
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  font-weight: 700;
  color: rgba(0,0,0,.87);
  white-space: normal;
  text-align: left;
  text-transform: none;
  color: #ff7000;
}

.tab-content {
  color: #616161;
}

.tab-content li {
  margin-bottom: 20px;
}

.card-body {
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.name {
  width: 60%;
}

.stand {
  width: 15%;
}

.liter {
  width: 25%;
}



.video-wrapper .video {
  margin-bottom: 20px;
  line-height: 0;
}

.video-wrapper .video{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.video-wrapper .video iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

#btn-clear {
  display: none;
}



/* Mobile navigation */

#nav-lang {
  padding-right: 50px;
}

.header__nav-mobile {
  display: block;
  width: 30px;
  height: 26px;
  top: 12px;
  right: 12px;
  position: absolute;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 4;
}

.header__nav-mobile span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #ffffff;
  opacity: 1;
  left: 0;
  transition: .25s ease-in-out;
}

.header__nav-mobile span:nth-child(1) {
  top: 0px;
}

.header__nav-mobile span:nth-child(2), .header__nav-mobile span:nth-child(3) {
  top: 9px;
}

.header__nav-mobile span:nth-child(4) {
  top: 18px;
}

.header__nav-mobile.nav-mobile-open span {
  background: #ff7000;
}


.header__nav-mobile.nav-mobile-open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}

.header__nav-mobile.nav-mobile-open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header__nav-mobile.nav-mobile-open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.header__nav-mobile.nav-mobile-open span:nth-child(4) {
  top: 20px;
  width: 0%;
  left: 50%;
}

.nav-mobile-content {
  background: #ffffff;
  position: absolute;
  margin: 0 auto;
  padding: 150px 20px 0px 20px;
  text-align: left;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: 1;
  animation-delay: 0;
  animation-duration: .25s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  opacity: 1;
  text-shadow: 0;
  float: none;
  font-size: 1.5em;
  font-weight: 400;
}

.nav-mobile-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-mobile-content li {
  padding: 0;
  margin: 0 0 20px;
  text-align: center;
}

.nav-mobile-content a {
  color: #000000;
  text-decoration: none;
}

.nav-mobile-content a:hover {
  border-bottom: 2px solid #0a253e;
  padding-bottom: 4px;
}

.nav-mobile-content.nav-mobile-open {
  animation-name: nav_mobile_open;
}

@keyframes nav_mobile_open {
  0% { left: -100%; }
  100% { left: 0; }
}

.nav-mobile-content.nav-mobile-close {
  animation-name: nav_mobile_close;
}

@keyframes nav_mobile_close {
  0% { left: 0; }
  100% { left: -100%; }
}

body.nav-mobile-open {
  overflow: hidden;
}

@media (min-width: 992px) {

  #header {
    margin-bottom: 0 !important;
  }
  #menu {
    display: block;
  }

  #nav-lang {
    padding-right: 0;
  }

  .header__nav-mobile, .nav-mobile-content {
    display: none;
  }
}
.display-4{
  font-weight: 900;
  margin-bottom: 20px;
  /* font-style: italic; */
}
#users-form{
  margin-bottom: 20px;
}
.table td, .table th{
  padding: 0.60rem 0.75rem;
}